<!--促销计划-->
<template>
    <div class="Plan">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" v-model="form" size="small" label-width="80px;">
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="计划名称">
                            <el-input placeholder="关键字" v-model="form.name" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="启用状态">
                            <el-select placeholder="请选择" v-model="queryData.status">
                                <el-option label="请选择" value="" />
                                <el-option label="启用" :value="1" />
                                <el-option label="停用" :value="2" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="开始日期">
                            <el-date-picker
                                type="date"
                                v-model="queryData.startTime"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                style="width: 200px"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="结束日期">
                            <el-date-picker
                                type="date"
                                v-model="queryData.endTime"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                style="width: 200px"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuery" size="small" v-if="hasPrivilege('menu.plan.goods.open')"
                >查询</el-button
            >
            <el-button type="primary" @click="handleCreate" size="small" v-if="hasPrivilege('menu.plan.goods.create')"
                >新建</el-button
            >
            <el-button type="primary" @click="handleExport" size="small" v-if="hasPrivilege('menu.plan.goods.export')"
                >导出</el-button
            >
            <el-button type="primary" @click="handleOn" size="small" v-if="hasPrivilege('menu.plan.goods.edit')"
                >批量启用</el-button
            >
            <el-button type="primary" @click="handleOff" size="small" v-if="hasPrivilege('menu.plan.goods.edit')"
                >批量停用</el-button
            >
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                ref="ProgrammeShow"
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="480"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="方案编号" width="240" prop="code" />
                <el-table-column label="商品名称" width="160" prop="goodsName" />
                <el-table-column label="购买数量" width="120" prop="count" />
                <el-table-column label="赠送数量" width="120" prop="presentCount" />
                <el-table-column label="售价" width="120" prop="price">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.price | money }}</span></template
                    >
                </el-table-column>
                <el-table-column label="启用状态" width="120" prop="status">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.status | status }}</span></template
                    >
                </el-table-column>
                <el-table-column label="创建时间" width="150" prop="createTime" />
                <el-table-column label="操作" min-width="100" header-align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'ProgrammeDetail',
    props: ['form'],
    data() {
        return {
            nowForm: this.form,
            queryData: {
                bizCode: this.form.code,
                name: '',
                status: '',
                startTime: Util.formatDateOfLastMonth(),
                endTime: Util.nowFormatDate(),
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            tableData: [],
            url: {
                page: '/merchandising/detail/pageList',
                delete: '/merchandising/detail/disable',
                handleOnAndOff: '/merchandising/detail/enableAndDisable',
            },
            current: {
                typeIdx: 1,
            },
            meta: {
                types: [],
                reviewEnums: Util.reviewStatusEnum(true),
            },
        };
    },
    mounted() {
        this.handleQuery();
    },
    beforeRouteEnter(to, from, next) {
        //代表从上一个页面返回的参数
        if (from.name == 'menu.plan.goodsDetail.create') {
            const temForm = from.params.form.backForm || {};
            to.params.form = temForm;
        }
        next();
    },
    methods: {
        handleOn() {
            const _this = this;
            const _a = [];
            let vaildStatus = false;
            if (_this.$refs['ProgrammeShow'].selection == 0) {
                _this.$message.error('请选择启用单据');
                return;
            }
            _this.$refs['ProgrammeShow'].selection.forEach((v) => {
                if (v.status != 2) {
                    vaildStatus = true;
                }
                _a.push(v.code);
            });
            if (vaildStatus) {
                _this.$message.error('请选择停用状态单据进行启用');
                return;
            }
            const _params = { codes: _a, status: 1 };
            UrlUtils.PatchRemote(_this, _this.url.handleOnAndOff, _params);
        },
        handleOff() {
            const _this = this;
            const _a = [];
            let vaildStatus = false;
            if (_this.$refs['ProgrammeShow'].selection == 0) {
                _this.$message.error('请选择停用单据');
                return;
            }
            _this.$refs['ProgrammeShow'].selection.forEach((v) => {
                if (v.status != 1) {
                    vaildStatus = true;
                }
                _a.push(v.code);
            });
            if (vaildStatus) {
                _this.$message.error('请选择启用状态单据进行停用');
                return;
            }
            const _params = { codes: _a, status: 2 };
            UrlUtils.PatchRemote(_this, _this.url.handleOnAndOff, _params);
        },

        handleDelete(row) {
            UrlUtils.DeleteRemote(this, this.url.delete, row.code);
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.queryData };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleCreate() {
            const row = this.form;
            row.backForm = this.nowForm;
            Util.nameJump(this, 'menu.plan.goodsDetail.create', ['促销管理', '商品计划', '新建方案'], {
                form: row,
                code: row.code,
            });
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.promotion.plan.detail', ['促销管理', '商品计划', '计划方案'], {
                form: row,
                code: row.code,
            });
        },
        handleExport() {
            UrlUtils.Export(this, '方案列表', '/merchandising/detail/export', this.queryData);
        },
    },
    filters: {
        ruleType(type) {
            switch (type) {
                case 1: {
                    return '单品折扣';
                }
                case 2: {
                    return '单品偶数折扣';
                }
                case 3: {
                    return '单品特价';
                }
            }
        },
        status(status) {
            switch (status) {
                case 1: {
                    return '启用';
                }
                case 2: {
                    return '停用';
                }
            }
        },
    },
};
</script>

<style scoped>
.Plan .el-form-item {
    margin-bottom: 0;
}
</style>
